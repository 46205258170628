import React, { Component } from 'react'
import TextLoop from "react-text-loop";
import './WelcomeStyle.css'

export default class Welcome extends Component {

    render() {
        return (
            <TextLoop >
                <div>
                    <span className = 'style' style = {{color:'white'}}>Visitors</span>
                </div>
                <div>
                    <span className = 'style' style = {{color:'white'}}>World!</span>
                </div>
                <div>
                    <span className = 'style' style = {{color:'white'}}>Explorers</span>
                </div>
                <div>
                    <span className = 'style' style = {{color:'white'}}>Friends</span>
                </div>
            </TextLoop>
        )
    }
}

