import React from 'react'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import './CardStyle.css'
import Rotate from 'react-reveal/Rotate';

export default function Cards() {

    const styleImage = {
        height:'250px',
        borderRadius:'11px 11px 0px 0px',
    } 
    return (
        <div>
            <div>
            <Rotate bottom left>
            <CardDeck >
           
            <Card className = 'cardStyle'>
                <Card.Img  style = {styleImage} src="https://i.ibb.co/4RQcHJs/Screen-Shot-2020-08-16-at-7-37-51-PM.png" />
                <Card.Body>
                <Card.Title><a href='https://my-java-script-calculator-project.vercel.app/' target='_blank'>Cool Calculator JavaScript</a></Card.Title>
                <Card.Text>
                    <a href ='https://github.com/Cristian-Castillo/My-JavaScript-Calculator-Project' target='_blank'>See Code</a>
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className = 'cardStyle'>
                <Card.Img  style = {styleImage} src="https://i.ibb.co/QjFFbNW/Screen-Shot-2020-08-16-at-7-38-10-PM.png" />
                <Card.Body>
                <Card.Title><a href = 'https://drumkit-funk-project.vercel.app/' target='_blank'>The DrumKit Funk</a></Card.Title>
                <Card.Text>
                <a href ='https://github.com/Cristian-Castillo/Drumkit-Funk-Project' target='_blank'>See Code</a>
                </Card.Text>
                </Card.Body>
            
            </Card>
            <Card className = 'cardStyle' >
                <Card.Img style = {styleImage} src="https://i.ibb.co/R05zc0f/Screen-Shot-2020-08-16-at-7-47-35-PM.png" />
                    <Card.Body >
                    <Card.Title><a href = 'https://pomodor-clock-project.vercel.app/' target='_blank'>Pomodor Clock </a></Card.Title>
                    <Card.Text>
                    <a href ='https://github.com/Cristian-Castillo/Pomodor-Clock-Project' target='_blank'>See Code</a>
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className = 'cardStyle'>
                <Card.Img  style = {styleImage} src="https://i.ibb.co/vx5mDLm/Screen-Shot-2020-08-16-at-8-43-49-PM.png" />
                <Card.Body>
                <Card.Title><a href='https://random-quote-generator-project.vercel.app/' target='_blank'>Random Quote Generator</a></Card.Title>
                <Card.Text>
                    <a href ='https://github.com/Cristian-Castillo/Random-Quote-Generator-Project' target='_blank'>See Code</a>
                </Card.Text>
                </Card.Body>
            </Card>
            </CardDeck>
            </Rotate>
            </div>
            <div style = {{marginTop:'50px'}}>
            <Rotate bottom right>
            <CardDeck >
            <Card className = 'cardStyle' >
                <Card.Img style = {styleImage} src="https://i.ibb.co/7vs35b7/Screen-Shot-2020-08-16-at-8-44-12-PM.png" />
                    <Card.Body >
                    <Card.Title><a href = 'https://codepen.io/ccarri11/full/vYLvzbd' target='_blank'>Portfolio Mockup</a></Card.Title>
                    <Card.Text>
                    <a href ='https://github.com/Cristian-Castillo/Fake-Portfolio-Project' target='_blank'>See Code</a>
                    </Card.Text>
                    </Card.Body>
                </Card>
            <Card className = 'cardStyle'>
                <Card.Img  style = {styleImage} src="https://i.ibb.co/QJc59wJ/Screen-Shot-2020-08-19-at-11-50-07-PM.png" />
                <Card.Body>
                <Card.Title><a href = 'https://simple-markdown-previewer-project.vercel.app/' target='_blank'>Simple Markdown Previewer</a></Card.Title>
                <Card.Text>
                <a href ='https://github.com/Cristian-Castillo/Simple-Markdown-Previewer-Project' target='_blank'>See Code </a>
 
                </Card.Text>
                </Card.Body>
            
            </Card>
            <Card className = 'cardStyle' >
                <Card.Img style = {styleImage} src="https://i.ibb.co/Jm20FYq/Screen-Shot-2020-08-16-at-8-45-56-PM.png" />
                    <Card.Body >
                    <Card.Title><a href = 'https://codepen.io/ccarri11/full/LYGJevX' target='_blank'>Survey Form</a></Card.Title>
                    <Card.Text>
                    <a href ='https://github.com/Cristian-Castillo/My-Survey-Form-Project' target='_blank'>See Code</a>
                    </Card.Text>
                    </Card.Body>
                </Card>
           
            <Card className = 'cardStyle' >
                <Card.Img style = {styleImage} src="https://i.ibb.co/x1vc3D7/Screen-Shot-2020-08-16-at-9-04-51-PM.png"/>
                    <Card.Body >
                    <Card.Title><a href = 'https://cse115b-plasticpollution.github.io/plasticpollution/#/' target='_blank'>EDU Plastic Pollution</a></Card.Title>
                    <Card.Text>
                    <a href ='https://github.com/CSE115b-PlasticPollution/plasticpollution' target='_blank'>See Code</a>
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            </Rotate>
            </div>
            
            </div>
            
            
    )
}


