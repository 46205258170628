import React from 'react'
import GridLayout from 'react-grid-layout';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css' 
import 'react-resizable/css/styles.css' 

export default class Projct extends React.Component {
  render() {
    // layout is an array of objects, see the demo for more complete usage
    const layout = [
      {i: 'a', x: 0, y: 0, w: 1, h: 2, static: true},
      {i: 'b', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
      {i: 'c', x: 4, y: 0, w: 1, h: 2}
    ];
    return (
      <GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>
        <div style = {{backgroundColor:'blue'}} key="a">a</div>
        <div style = {{backgroundColor:'green'}} key="b">b</div>
        <div style = {{backgroundColor:'pink'}} key="c">c</div>
      </GridLayout>
    )
  }
}