import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import './ContainerStyle.css'
import CristianPicture from './Cris.png'
import Table from 'react-bootstrap/Table'
import Welcome from './Welcome'
import Project from './Project'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Cards from './Cards'
import { FaGithubSquare,FaLinkedin } from "react-icons/fa";
import Media from 'react-bootstrap/Media'
import {RiNewspaperLine} from 'react-icons/ri'
import Pdf from './MyResume.pdf'
import {AiOutlineMail} from "react-icons/ai";
import {FaGithub} from "react-icons/fa";
import {GrLinkedin} from "react-icons/gr";
import {GrFacebook} from "react-icons/gr";
import {FaRegArrowAltCircleUp} from 'react-icons/fa'
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';

export default class Container extends Component {


    render() {

        const TableFormat = {textAlign:'left',borderStyle:'1.5 solid black'};
        
        let scrollToElement = require('scroll-to-element');

        // scrollToElement('#home');
        scrollToElement('#aboutSection');
        scrollToElement('#projectSection');
        scrollToElement('#educationSection');
        scrollToElement('#contactSection');

                // with options
        scrollToElement('foo', {
            offset:-50,
            duration: 1500
        });
 

        const scrollFormat = {
            scrollBehavior: 'smooth'
        };
    
        
        return (
            <div >
                <div className = 'foo'>
                    <header >
                    {/* Begin of Navbar */}
                    <Navbar  className = 'navStyle' collapseOnSelect expand="lg">
                        <Navbar.Brand className ='foo' href="#">Cristian's Portfolio</Navbar.Brand>
                        <Navbar.Toggle className = 'hamburgerStyle' aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse className = 'hamburgerStyle' id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                            <Nav.Link href="#aboutSection">01. About me</Nav.Link>
                            <Nav.Link href="#educationSection">02. Education</Nav.Link>
                            <Nav.Link href="#projectSection">03. Projects</Nav.Link>
                            <Nav.Link href="#contactSection">04. Contact</Nav.Link>
                            </Nav>
                            <Nav >
                            <Nav.Link href='#' href={Pdf} target ='MyResume.js'>Resume <RiNewspaperLine /></Nav.Link>
                            <Nav.Link href="#deets" ><a className = 'githubLink' href='https://github.com/Cristian-Castillo' target='_blank' ><FaGithubSquare /></a></Nav.Link>
                            <Nav.Link eventKey={2} href="#memes">
                            <a className = 'githubLink' href='https://www.linkedin.com/in/cristian-c-castillo-28605b1b1/' target='_blank'><FaLinkedin/></a>
                            </Nav.Link>               
                            </Nav>
                        </Navbar.Collapse>
                        </Navbar>
                    {/* End of Navbar */}
                    </header>
                    <div ></div>
                </div>
                    <div  className = 'app-header foo' >

                        <div id ='home' className= 'container-fluid'>
                        <Fade top>
                            <div className = 'intro'>
                                <h1 className = 'welcomeHello'><span>{'< '}Hello <Welcome /></span>{' />'}</h1>
                            </div>
                            <div className = 'introStyle'>
                                <h6>Hi, my name is</h6>
                                <h1>Cristian Castillo.</h1>
                                <h1 className = 'titleSentence'>I build software applications for the web.</h1><br></br>
                            </div>
                            <div className = 'introLayout'>
                                <p>I'm a passionate software engineer based in<br></br> Oakland, CA  who enjoys building exceptional<br></br> applications and everything in between.</p>
                                <h5 ><a href='mailto:cris.c.castillo1@gmail.com'><button className = 'GetInTouchStyle'>Get In Touch</button></a></h5>
                            </div>
                            </Fade>
                            <Fade bottom>
                            <div id='aboutSection' style = {scrollFormat}>
                                <div style = {{marginTop:'370px'}} className = 'aboutMeLine'><h3>01. About me</h3></div>
                                <div className = 'row aboutMeLayout'>
                                    <img src = {CristianPicture} alt='Cristian' />
                                    <p>Hi, I'm Cristian, a passionate software engineer based in Oakland, CA.<br></br><br></br>I enjoys building exceptional applications. In my free time, I like to learn about new technologies, learn how things work, and spend time with my dog Otis. My goal and passion are to create high-quality and meaningful products that have an impact on everyday life like you and me! Currently, I am a senior student at the <a className ='santaCruzLink' style={{fontWeight:'700'}} href = 'https://www.ucsc.edu/'>University of California Santa Cruz</a>, and studying computer science.<br></br><br></br>Here are some of the technologies that I have used:</p> 
                                </div>
                                <div style = {{borderStyle:'1.5px solid black'}}>
                                <Table style = {{borderStyle:'1.5px solid black !important',marginBottom:'100px'}} className = 'tableSkill' responsive bordered hover >
                                        <tbody style={TableFormat}>
                                            <tr style = {{borderStyle:'1.5px solid black'}}>
                                            <td style = {{borderStyle:'1.5px solid black'}}>
                                                {" "}
                                                <b >JavaScript</b>
                                            </td>
                                            <td style = {{borderStyle:'1.5px solid black'}}>
                                                {" "}
                                                <b>React</b>
                                            </td>
                                            <td>
                                                {" "}
                                                <b>Redux</b>
                                            </td>
                                            <td>
                                                {" "}
                                                <b>DOM</b>
                                            </td>
                                            
                                            </tr>
                                            <tr>
                                            <td>
                                                {" "}
                                                <b>jQuery</b> 
                                            </td>
                                            <td>
                                                {" "}
                                                <b>HTML/CSS</b>
                                            </td>
                                            <td>
                                                {" "}
                                                <b>Sass</b>
                                            </td>
                                            <td>
                                                {" "}
                                                <b>Bootstrap</b>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                {" "}
                                                <b>C++</b>
                                            </td>
                                            <td>
                                                {" "}
                                                <b>Python</b>
                                            </td>
                                            <td>
                                                {" "}
                                                <b>Java</b>
                                            </td>
                                            <td>
                                                {" "}
                                                <b>MIPS</b>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            </Fade>
                            <div>
                            <div style = {{marginTop:'365px'}} id = 'educationSection' className = 'aboutMeLine'><h3  style = {scrollFormat}>02. Education</h3></div>
                            <div className = 'educationStyle' >
                            <ul className="list-unstyled">
                            <Slide left>
                                <Media as="li">
                                    <Media.Body className = 'mediaBody'>
                                    <a href = 'https://www.ucsc.edu/' target = '_blank'>
                                    <img
                                    width={64}
                                    height={64}
                                    className="mr-3"
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/The_University_of_California_1868_UCSC.svg/1200px-The_University_of_California_1868_UCSC.svg.png"
                                    alt="UCSC"
                                    /></a>
                                    <h5>University Of California Santa Cruz</h5>
                                    <p>Computer Science Bachelor's Degree in Science (GPA: 3.46/4.0)</p>
                                    <p>Graduation Date: December 6, 2020</p>
                                    
                                    </Media.Body>
                                </Media>
                                </Slide>
                                <Slide right>
                            <Media as="li">
                                <Media.Body className = 'mediaBody'>
                                <a href = 'https://laney.edu/' target = '_blank'>
                                <img 
                                width={64}
                                height={64}
                                className="mr-3"
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS49RiQ7HoF4kZV1j-ycsgOHEGyOEwOoQ5yiA&usqp=CAU"
                                alt="Laneyr"
                                /></a>
                                <h5>Laney College</h5>
                                <p>Associates of Science in Mathematics - Honors</p>
                                <p>Graduation Date: July 6, 2018</p>
                                </Media.Body>
                            </Media>
                            </Slide>
                            <Slide left>
                            <Media as="li" >
                                <Media.Body className = 'mediaBody' >
                                <a href = 'https://www.freecodecamp.org/cris-castillo' target = '_blank'>
                                <img
                                width={64}
                                height={64}
                                className="mr-3"
                                src="https://pbs.twimg.com/profile_images/782132102368223232/yJxwuI1g_400x400.jpg"
                                alt="Free Code Camp"
                                /></a>
                                <h5>Free Code Camp</h5>
                                <p>Certificates: <br></br><a className = 'certLink' href = 'https://www.freecodecamp.org/certification/cris-castillo/javascript-algorithms-and-data-structures' target = '_blank'>JavaScript Algorithms & Data Structures</a><br></br><a className = 'certLink' href ='https://www.freecodecamp.org/certification/cris-castillo/responsive-web-design' target = '_blank'>Responsive Web Design</a><br></br><a className = 'certLink1' href = 'https://www.freecodecamp.org/certification/cris-castillo/front-end-libraries' target = '_blank'>Front End Libraries</a></p>
                                
                                </Media.Body>
                            </Media>
                            </Slide>
                            </ul>
                            </div>
                            <div style ={{marginBottom:'480px'}} id='projectSection' style = {{marginTop:'-5px'}}className = 'aboutMeLine'><h3  style = {scrollFormat}>03. Projects</h3></div>
                            <div  className ='projectClass'>
                                <Cards  />
                            </div>
                            </div>
                            <div  style = {{marginTop:'440px'}}className = 'aboutMeLine'><h3  style = {scrollFormat}>04. Contact</h3></div>
                            <div id='contactSection'><Zoom bottom>
                                <h2 style = {{marginTop:'150px'}}>Lets collaborate!</h2>
                                <div className = 'container-fluid' style = {{marginTop:'80px'}}>
                                    <a href= 'https://www.facebook.com/cristian.carrillocastillo' target ='_blank'><GrFacebook className = 'iconStyle' style = {{width:'155px',height:'155px',margin:'35px'}}/></a><a href = 'mailto:cris.c.castillo1@gmail.com' target = '_blank'><AiOutlineMail style = {{width:'155px',height:'155px',margin:'35px'}}className = 'iconStyle' style = {{width:'155px',height:'155px',margin:'35px'}}/></a><a href = 'https://github.com/Cristian-Castillo' target='_blank'><FaGithub className = 'iconStyle' style = {{width:'155px',height:'155px',margin:'35px'}}/></a><a href = 'https://www.linkedin.com/in/cristian-c-castillo-28605b1b1/' target ='_blank'><GrLinkedin className = 'iconStyle'style = {{width:'150px',height:'150px'}} /></a>
                                </div>
                                </Zoom>
                                <Bounce bottom>
                                <div style = {{marginTop:'355px'}}  className ='foot'>
                                    <a href = '#' className = 'foo' ><FaRegArrowAltCircleUp className ='footArrow' style = {{height:'40px', width:'40px'}}/></a>
                                </div>
                                </Bounce>

                            </div>
                            <h6 style = {{color:'#00e611'}}>Design & Coded By Cristian C. Castillo</h6>

                        </div>

                    </div>
                </div>
                            
                    
    
        )
    }
}
